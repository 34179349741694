const search = {
  namespaced: true,
  state: {
    keywordMaxLength: 100,
    searchParams: {
      // keyword: "",
      // sortType: 0,
      // pageChoiceType: "",
      // labelType: "",
      // oneCategoryType:'',
      // secondCategoryType:'',
      // thirdCategoryType:'',
    }, // 搜索solr入参
    isGetDataFromId: {},
    choseData: {},
    isHandleReset: false,
  },
  getters: {
    keywordMaxLength: (state) => state.keywordMaxLength,
    searchParams: (state) => state.searchParams, // 搜索solr入参
    isGetDataFromId: (state) => state.isGetDataFromId,
    isHandleReset: (state) => state.isHandleReset,
  },
  mutations: {
    updateSearchParams(state, searchParams) {
      // 更新搜索solr入参
      state.searchParams = searchParams;
    },
    updateIsGetDataFromId(state, isGetDataFromId) {
      state.isGetDataFromId = isGetDataFromId;
    },
    updateChoseData(state, updateChoseData) {
      state.choseData = { ...state.choseData, ...updateChoseData };
    },
    updateIsHandleReset(state, isHandleReset) {
      state.isHandleReset = isHandleReset;
    },
  },
  actions: {
    getDataFromId(store, data) {
      store.commit("updateIsGetDataFromId", data);
      setTimeout(() => {
        data.callback(store.state.choseData);
      }, 300);
    },
  },
};
export default search;
