import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

import systemInit from "@/utils/system";
// 解决 NavigationDuplicated: Avoided redundant navigation to current location 报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
Vue.use(VueRouter);

const routes = [
  /** 首页 **/
  {
    path: "/",
    meta: {
      header: true,
    },
    name: "千里马-千里马招标网|招投标|国内招标行业门户网站",
    component: () => import("@/views/List/Index"),
  },
  {
    path: "/login",
    name: "登录",
    meta: {
      header: false,
    },
    component: () => import("@/views/Login/Index"),
  },
  /** 搜索列表页 **/
  {
    path: "/list",
    name: "搜索列表页",
    meta: {
      header: true,
    },
    component: () => import("@/views/List/Index"),
  },
  /** 由由招标要求编写  **/
  {
    path: "/bid-create",
    name: "由招标要求编写",
    meta: {
      header: true,
    },
    component: () => import("@/views/BidCreate/Index"),
  },
  /** 由��标要求编写DirectoryTree **/
  {
    path: "/bid-directory-tree",
    name: "由招标要求编写",
    meta: {
      header: true,
    },
    component: () => import("@/views/BidDirectoryTree/Index"),
  },
  /** 由目录编写 **/
  {
    path: "/directory-create",
    name: "由目录编写",
    meta: {
      header: true,
    },
    component: () => import("@/views/DirectoryCreate/Index"),
  }, //
  /** 由目录编写 **/
  {
    path: "/edit-cont",
    name: "编写",
    meta: {
      header: true,
    },
    component: () => import("@/views/EditCont/Index"),
  },
  // 错误路由时通配符跳转
  {
    path: "*",
    name: "",
    component: () => import("@/views/List/Index"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach(async (to, from, next) => {
  /** 路由发生变化，修改pageTitle **/
  store.commit("basic/updatePageTitle", to.name || to.matched[0].name || "");
  if (to.path !== "/login") {
    await systemInit.setUserInfo();
  }
  store.commit("basic/updateHeader", to.meta.header);
  console.log(to.meta);
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
  next();
});

export default router;
