import { config } from "@/utils/config";
import { omniAxios } from "@/utils/axios";
//获取用户信息
export function restUCompanyGetCompanyInfo() {
  return omniAxios({
    url: config.restUCompanyGetCompanyInfoPath,
    method: "get",
    params: {},
    isTwoPointZeroInterfaceApi:true,
    useDevServerProxy:true,
    showLoading:true

  });
}

// 公共埋点
export function userBehaviorInsertRecordWebsite(params) {
  return omniAxios({
    url: config.userBehaviorInsertRecordWebsitePath,
    method: "post",
    params,
    isTracker: true, // 埋点类接口
    withCredentials: false,
  });
}


export function restUserLogin(params){
  return omniAxios({
    url: config.restUserLoginPath,
    method: "post",
    params,
  });
}
export function restUserLogout(params){
  return omniAxios({
    url: config.restUserLogoutPath,
    method: "post",
    params,
  });
}