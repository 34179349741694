import Vue from "vue";
import Vuex from "vuex";
import basic from "./basic";
import search from "./search";
Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    basic,
    search,
  },
});
