/**
 * @author liqiuqi
 * @date 2022 2022/4/24 13:55
 */
import { Message } from "element-ui";
// import { restUCompanyGetCompanyInfo } from "@/services/home";
import store from "@/store";
import { Cookies } from "./cookieKey";
async function setUserInfo() {
  // 用户信息bidToken
  if (Cookies.get("bidUserInfo") && Cookies.get("bidToken")) {
    store.commit("basic/updateUserInfo", JSON.parse(Cookies.get("bidUserInfo")));
    return;
  } else {
    Message.error("请先登录！");
    window.location.href = "/login";
    return;
  }
  // try {
  //   userInfo = await restUCompanyGetCompanyInfo().then((res) => res.data);
  // } catch (err) {
  //   console.log(err, "获取用户信息失败");
  // }

  // store.commit("basic/updateUserInfo", {
  //   ...userInfo,
  // });
}

function basicFunctionInit() {
  // 重写 window.open
  window.openATag = (url) => {
    const form = document.createElement("a");
    form.href = url;
    form.target = "_blank";
    document.body.appendChild(form);
    form.click();
    form.remove();
  };
}

function basicListenersInit() {
  window.addEventListener("online", () => {
    Message.closeAll();
    console.log("online");
  });
  window.addEventListener("offline", () => {
    Message({
      message: "您的网络已断开，请检查网络连接是否正常",
      type: "error",
      duration: 0,
      showClose: true,
    });
    console.log("offline");
  });
}

const systemInit = {
  setUserInfo,
  basicFunctionInit,
  basicListenersInit,
};

export default systemInit;
