<template>
  <div class="LayoutHeader" ref="LayoutHeader" :class="{ headerFixed }">
    <div class="LayoutHeader-cont">
      <div class="header-left">
        <img class="logo" alt="千里马招标信息" @click="handleLogo" src="@/assets/img/header/logo.png" />
      </div>

      <div class="header-right">
        <template>
          <div v-if="getLoginStatus()" class="avatar">
            <el-avatar
              shape="square"
              :src="require(`@/assets/img/header/default_avatar${userInfo.sex === 'f' ? '_f' : ''}.png`)"
            ></el-avatar>
            <span>{{ userInfo.username }}</span>
            <span class="login-out" @click="outUser">退出</span>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { getLoginStatus, logOutOfLogin } from "@/utils/common";
import { restUserLogout } from "@/services/home";

export default {
  name: "SearchHeader",
  components: {},
  data() {
    return {
      keyWord: "",
      headerFixed: false,
    };
  },
  computed: {
    userInfo() {
      return this.$store.getters["basic/userInfo"];
    },
  },
  methods: {
    getLoginStatus,
    handleScroll(e) {
      // 距顶部
      this.scrollTop = e.target.scrollTop;
      this.headerFixed = this.scrollTop > this.fixedHeight;
    },
    goToUrl() {
      window.open("/personalCenter");
    },
    handleLogo() {
      window.location.href = "/";
    },
    outUser() {
      this.$confirm("确认退出登陆？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        customClass: "customClass	",
      })
        .then(() => {
          restUserLogout({ userId: this.userInfo.userId }).then(() => {
            logOutOfLogin();
            this.$router.push({ path: "/login" });
          });
        })
        .catch(() => {});
    },
  },
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang="scss">
.customClass {
  .el-message-box__btns {
    border-top: 0 !important;
  }
}
</style>
<style scoped lang="scss">
@import "@/assets/config";

.LayoutHeader {
  width: 100%;
  background: url("@/assets/img/header/header-search-bg1.png") no-repeat;
  background-size: 100% 68px;
  min-width: $layout-body-width;
  .LayoutHeader-cont {
    width: $layout-body-width;
    margin: 0 auto;
    height: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    padding: 14px 0;
  }

  .header-left {
    display: flex;
    float: left;
    .logo {
      cursor: pointer;
      width: 185px;
      height: 40px;
    }
  }

  .header-right {
    display: flex;

    .userInfo {
      background: url("@/assets/img/header/avator.png") no-repeat;
      background-size: 22px 22px;
      background-position-x: left;
      background-position-y: center;
      padding-left: 24px;
    }
  }
  .LibHeaderInput-box {
    flex: 1;
    position: relative;
    // padding-left: 70px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    // padding-top: 24px;
  }

  .avatar {
    display: flex;
    align-items: center;
    color: #282828;
    cursor: pointer;
    line-height: 16px;
    font-size: 16px;
    span {
      margin-left: 12px;
    }
    .login-out {
      display: block;
      border-left: 1px solid #282828;
      padding-left: 16px;
      margin-left: 16px;
    }
  }
}
</style>
