/** 网络、接口 相关配置 **/
export const axiosConfig = {
  // common
  HTTP_CODE: {
    SUCCESS: 200,
    UNAUTHORIZED: 401,
    PAYMENT_REQUIRED: 402,
    NO_AUTHORISE: 403,
    REDO_LOGIN: 406,
    ACCESS_CAPTCHA_PERMISSION_429: 429,
    TOO_FREQUENTLY_521: 521,
    
  },

  STATUS_CODE: {
    SUCCESS: 200,
    SERVER_ERR: 503,
    TO_SUBSCRIBE: 500500,
    REQUEST_CANCEL: "REQUEST_CANCEL",
    PERMISSION_EXPIRATION: 310300, // 权限过期
    ACCESS_CAPTCHA_PERMISSION_IS_MISSING: 200622, // 需要滑动验证刷新权限
  },
};
