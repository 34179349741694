/** 静态变量 **/
export const CDN = process.env.VUE_APP_OSS_CDN;
export const PUBLIC_PATH = "//" + window.location.hostname; //本项目域名
export const OLD_USER_CENTER_URL = process.env.VUE_APP_OLD_USER_CENTER_URL; //会员中心
export const TITLE = "千里马招标网";
export const OFFICIAL_WEBSITE = "//www.qianlima.com"; //旧版首页;

export const BEIJING_ICP_CERTIFICATE_URL = "https://beian.miit.gov.cn/"; // 京ICP证160587号
export const BEIJING_PUBLIC_NETWORK = "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802022728"; //京公网安备 11010802022728号

export const SALE_BEFORE_URL = "http://customer.qianlima.com/sale_before"; //在线咨询

export const SERVICE_HOTLINE = "400-688-2000";
export const STATUS = [
  { label: "目录编写中", value: 1 },
  { label: "目录已完成", value: 2 },
  { label: "内容待编写", value: 3 },
  { label: "内容编写中", value: 4 },
  { label: "生成完成", value: 5 },
];
export const BASE_DATA = [
  { label: "AI模型", value: "0" },
  { label: "模板库", value: "1" },
  { label: "私有知识库", value: "2" },
];
