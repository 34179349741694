/** axios封装 **/
import axios from "axios";
import { axiosConfig } from "./axiosConfig";
import { Message, MessageBox } from "element-ui";
import { Cookies } from "./cookieKey";
import Qs from "qs";
import { logOutOfLogin, getUUID } from "@/utils/common";
import {
  cancelRequest,
  lastRequestStatus,
  requestAdd,
  requestDelete,
  updateLastRequestStatus,
} from "@/utils/axios.canceler";
import store from "../store";

// 登出提醒当前是否已显示
let isLogoutToastOnShow = false;

async function handleSuccessResponse(res) {
  store.commit("basic/updateLoading", false);
  updateLastRequestStatus(res.status);
  if (res.status === axiosConfig.HTTP_CODE.SUCCESS) {
    if (res.config.responseType === "blob") {
      return Promise.resolve(res);
    }
    /** 判断业务code **/
    const code = parseInt(res.data.code);
    switch (code) {
      /** code 为 200 **/
      case axiosConfig.STATUS_CODE.SUCCESS:
        return Promise.resolve(res.data);
      /** code 为 503 **/
      case axiosConfig.STATUS_CODE.SERVER_ERR:
        Message.error(`网络错误，请稍后重试[SERVER_ERR ${code}]`);
        return Promise.reject(res.data);
      default:
        return Promise.reject(res.data);
    }
  } else {
    return Promise.reject(res.data);
  }
}

async function handleErrorResponse(response) {
  const status = response && response.status;
  const data = (response && response.data) || {};
  store.commit("basic/updateLoading", false);

  switch (status) {
    case axiosConfig.HTTP_CODE.UNAUTHORIZED:
      /** 401 **/
      if (isLogoutToastOnShow) return;
      isLogoutToastOnShow = true;
      MessageBox({
        title: "提示",
        message: "登录状态超时，请重新登录!",
        type: "error",
        showCancelButton: false,
        customClass: "customClass	",
      }).then(() => {
        isLogoutToastOnShow = false;
        logOutOfLogin();
      });
      return Promise.reject(data);
    case axiosConfig.HTTP_CODE.PAYMENT_REQUIRED:
      /** 402 **/
      if (isLogoutToastOnShow) return;
      isLogoutToastOnShow = true;
      Message({
        message: "账号已在其他设备登录，请重新登录",
        type: "info",
        onClose: () => {
          isLogoutToastOnShow = false;
          logOutOfLogin();
        },
      });
      return Promise.reject(data);
    case axiosConfig.HTTP_CODE.NO_AUTHORISE:
      /** 403 **/
      if (isLogoutToastOnShow) return;
      isLogoutToastOnShow = true;
      MessageBox({
        title: "提示",
        message: "登录状态超时，请重新登录!",
        type: "error",
        showCancelButton: false,
      }).then(() => {
        isLogoutToastOnShow = false;
        logOutOfLogin();
      });
      return Promise.reject(data);

    default: // canceler
      /** 不满足所有case时，直接返回response本身 **/
      if (response.message && response.message.includes("Axios Canceler")) {
        console.warn(response.message);
        response = {
          ...response,
          status: axiosConfig.STATUS_CODE.REQUEST_CANCEL,
        };
      }
      return Promise.reject(response);
  }
}

function getHeaders(isFormData) {
  return {
    Authorization: Cookies.get("bidToken"),
    "Content-Type": isFormData ? "application/x-www-form-urlencoded" : "application/json",
  };
}
/**
 * Axios二次封装
 * @param url 请求路径
 * @param method 请求方法
 * @param params 请求参数
 * @param isFormData 入参是否为form表单
 * @param withCredentials 是否携带身份认证
 * @param timeout 超时时间，默认无超时时间，毫秒
 * @param isTwoPointZeroInterfaceApi 是否是2.0pc端接口
 * @param useDevServerProxy 开发阶段是否使用反向代理
 * @param header 特殊接口需要单独添加的header信息
 * @param isTracker 是否为埋点接口
 * @param cancelLastSameRequest 当浏览器中存在相同url路径且在pending中的请求时，是否需要中断上一个
 * @param showLoading 是否显示全局loading
 * @returns {Promise<* | undefined>}
 * @responseType responseType
 */
export function omniAxios(options) {
  const {
    url,
    method = "get",
    params = {},
    withCredentials = true,
    timeout = 0,
    isFormData = false,
    useDevServerProxy = true,
    header = {},
    isTracker = false,
    cancelLastSameRequest = false,
    isTwoPointZeroInterfaceApi = false,
    showLoading = false,
    responseType = "",
  } = options;
  if (showLoading) {
    store.commit("basic/updateLoading", showLoading);
  }
  const requestId = getUUID();
  let baseURL = process.env[`VUE_APP_API`] === "/" ? window.VUE_APP_API || "" : process.env[`VUE_APP_API`];
  let headers = {
    ...getHeaders(isFormData),
    ...header,
  };
  console.log(baseURL, "baseURL", process.env[`VUE_APP_API`]);
  if (process.env.NODE_ENV === "development" && useDevServerProxy) {
    baseURL = "/proxyApi";
  }
  if (isTracker) {
    /**埋点是否使用反向代理的判断 **/
    if (process.env.NODE_ENV === "development" && useDevServerProxy) {
      baseURL = "/proxyApiTrack";
    } else {
      baseURL = process.env[`VUE_APP_API_TRACK`];
    }
  }
  if (isTwoPointZeroInterfaceApi) {
    if (process.env.NODE_ENV === "development" && useDevServerProxy) {
      baseURL = "/proxy2api";
    } else {
      baseURL = process.env[`VUE_APP_API_2`];
    }
  }

  const axiosOptions = {
    baseURL,
    url,
    method,
    headers,
    timeout,
    withCredentials,
    responseType,
    cancelToken: new axios.CancelToken(function (canceler) {
      /** 如上次请求回调为401，则取消本次请求 **/
      if (lastRequestStatus === axiosConfig.HTTP_CODE.UNAUTHORIZED) {
        cancelRequest({
          canceler,
          requestId,
          path: url,
          reason: "UNAUTHORIZED",
        });
      }
      /** 中断pending中的相同请求 **/
      if (cancelLastSameRequest) {
        cancelRequest({ path: url, reason: "Same request is pending" });
      }
      requestAdd({
        requestId,
        path: url,
        canceler,
      });
    }),
  };
  switch (method.toLowerCase()) {
    case "get":
      axiosOptions.params = params;
      break;
    case "post":
      axiosOptions.data = isFormData ? Qs.stringify(params) : params;
      break;
  }
  // 确保请求携带cookie
  // axios.defaults.withCredentials = withCredentials;
  return axios(axiosOptions)
    .then((res) => {
      store.commit("basic/updateLoading", false);
      requestDelete(requestId);
      return handleSuccessResponse(res);
    })
    .catch((e) => {
      store.commit("basic/updateLoading", false);
      return handleErrorResponse(e.response || e, options);
    });
}
