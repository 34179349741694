import Vue from "vue";
import App from "./App";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "@/assets/fade-in.css";
import "@/assets/element-variables.scss";
import "@/assets/normalize.scss";
import "@/assets/iconfont.css";
import "@/assets/commonflex.scss";
import systemInit from "@/utils/system";
import VueLazyComponent from "@xunlei/vue-lazy-component";
// import trackDirective from "@/utils/tracker.js";
Vue.config.productionTip = false;
console.log("process.env:", process.env);
Vue.use(ElementUI);
Vue.use(VueLazyComponent);
// Vue.use(trackDirective);

try {
  // systemInit.basicFunctionInit();
  systemInit.basicListenersInit();
} catch (e) {
  console.log(e, "出错");
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
