import { axiosConfig } from "./axiosConfig";

let pendingRequests = [];
let lastRequestStatus = axiosConfig.HTTP_CODE.SUCCESS;

const updateLastRequestStatus = (status) => {
  lastRequestStatus = status;
};

const requestAdd = ({ requestId, path, canceler }) => {
  pendingRequests.push({ requestId, path, canceler });
};

const requestDelete = (requestId) => {
  pendingRequests = pendingRequests.filter((value) => value.requestId !== requestId);
};

const cancelRequest = ({ canceler, requestId, path, reason }) => {
  if (!canceler && !requestId && !path) return;
  /** if canceler received, cancel this 'Canceler' immediately and return **/
  if (canceler) return canceler(`Because of [${reason}], request [${path}] has been canceled - Axios Canceler`);

  /** otherwise, cancel pending request from pendingRequests list **/
  let pendingRequest;

  if (requestId) pendingRequest = pendingRequests.find((value) => value.requestId === requestId);
  if (path) pendingRequest = pendingRequests.find((value) => value.path === path);

  if (pendingRequest) {
    requestId = pendingRequest.requestId;
    path = pendingRequest.path;
    pendingRequest.canceler(`Because of [${reason}], request [${path}] has been canceled - Axios Canceler`);

    requestDelete(requestId);
  }
};

export { lastRequestStatus, updateLastRequestStatus, pendingRequests, requestAdd, requestDelete, cancelRequest };
