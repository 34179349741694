
const basic = {
  namespaced: true,
  state: {
    pageTitle: "",
    userInfo: {
      dwmc: "",
      linkName: "",
      zhiwu: "",
    },
    loading:false,
    header:true,
  },
  getters: {
    pageTitle: (state) => state.pageTitle,
    userInfo: (state) => state.userInfo,
    loading:(state) => state.loading,
    header:(state) => state.header,
  },
  mutations: {
    updatePageTitle(state, pageTitle) {
      state.pageTitle = pageTitle;
    },
    updateUserInfo(state, userInfo) {
      state.userInfo = userInfo;
    },
    updateLoading(state, loading) {
      state.loading = loading;
    },
    updateHeader(state, header) {
      state.header = header;
    },
  },
  actions: {
  },
};
export default basic;
