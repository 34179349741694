<template>
  <div class="page" id="pageScroll">
    <LayoutHeader v-if="header" />
    <router-view v-loading="loading" element-loading-text="加载中请稍后..." element-loading-spinner="el-icon-loading" />
  </div>
</template>

<script>
import { TITLE } from "@/utils/constants/constants";
import LayoutHeader from "@/components/LayoutHeader";

export default {
  name: "App",
  components: { LayoutHeader },
  data() {
    return {
      showLoading: false,
    };
  },
  computed: {
    pageTitle() {
      return this.$store.getters["basic/pageTitle"];
    },
    loading() {
      return this.$store.getters["basic/loading"];
    },
    header() {
      return this.$store.getters["basic/header"];
    },
  },
  watch: {
    pageTitle: {
      handler(newV) {
        document.title = newV ? `${newV} - ${TITLE}` : TITLE;
      },
      immediate: true,
    },
    loading: {
      handler(newV) {
        this.showLoading = newV;
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.page {
  height: 100%;
  overflow: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
</style>
