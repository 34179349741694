/** 网络、接口 相关配置 **/
export const config = {
  // common

  // 搜索栏
  apiV1Path: "/api/v1",

  restUCompanyGetCompanyInfoPath: "/rest/u/company/getCompanyInfo", // 获取用户信息

  // // 图形验证码
  // restCaptchaGetPath: "/rest/captcha/get",
  // restCaptchaCheckPath: "/rest/captcha/check",
  ////图形验证没有登录
  // restCaptchaNoLoginCheckPath: "/rest/captcha/nologin/check",
  // // 埋点
  // userBehaviorInsertRecordWebsitePath: "/user/behavior/insertRecordWebsite", //公共埋点

  //登录 /user/login
  restUserLoginPath: "/user/login",
  //退出 /user/login
  restUserLogoutPath: "/user/logout",
  //搜索列表/task/list
  restTaskListPath: "/task/list",
  ///方案类型及其对应的二级类型
  restGetFileCategoriesPath: "/task/getFileCategories",
  //由招标要求编写-编写目录
  restTaskGenerateByRequirementPath: "/task/generateByRequirement",
  ///更新目录
  restTaskUpdateDirectoryPath: "/task/updateDirectory",
  //智能修改 /task/smartUpdateDirectory
  restTaskSmartUpdateDirectoryPath: "/task/smartUpdateDirectory",
  ///由招标要求编写-根据任务id回写 task/findTaskByCId/{cid}
  restTaskFindTaskByCIdPath: "/task/findTaskByCId/",
  //文件下载/task/downAiFile/{cid}
  restTaskDownAiFilePath: "/task/downAiFile/",
  //由目录编写-上传招标文件
  retTaskUploadRequirementFilePath: "/task/uploadRequirementFile",
  //由目录编写-上传方案目录文件/task/uploadDirectoryFile
  retTaskUploadDirectoryFilePath: "/task/uploadDirectoryFile",
  //由目录编写-编写内容/task/addTaskByDirectory
  retTaskAddTaskByDirectoryPath: "/task/addTaskByDirectory",
  ///获取编写详情
  retTaskGetTaskDetailPath: "/task/getTaskDetail/",
  ///获取某目录节点的所有版本
  retTaskGetAllVersionsPath: "/task/getAllVersions/",
  //目录节点AI生成内容
  restTaskAiGeneratePath: "/task/aiGenerate/",
  //目录节点AI+的确定按钮
  restTaskAiPlusGeneratePath: "/task/aiPlusGenerate",
  ///task/getContentByVersionOfNode  切换某目录节点的正文版本
  restTaskGetContentByVersionOfNodePath: "/task/getContentByVersionOfNode",
  //保存某目录节点的正文版本
  restTaskSaveAsVersionOfNodePath: "/task/saveAsVersionOfNode",
  ///按关键词检索 知识库
  restTaskRagSearchPath: "/task/ragSearch/",
  ///【弹窗】知识库-确定按钮
  restTaskSaveAsVersionByRagPath: "/task/saveAsVersionByRag",
  //一键编写（异步）
  restTaskAutoGenerateContentPath: "/task/autoGenerateContent/",
  //招标信息生成目录
  restTaskDirectoryTreePath: "/task/directory/tree/",
  //列表跳转
  restTaskRoutePath: "/task/route/",
};
