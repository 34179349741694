import _ from "lodash";
import store from "@/store";
import { Cookies } from "@/utils/cookieKey";
import { v4 as uuidv4 } from "uuid";
/** 服务热线 **/
export function getServicePhone() {
  try {
    if (store.getters["basic/isPayBefore"]) {
      return "400-900-6616";
    } else {
      return "400-688-2000";
    }
  } catch (e) {
    return "400-900-6616";
  }
}

/**
 * 判断数据是否为空
 * @param {*} value 数据
 * @returns boolean
 */
export function isValEmpty(value) {
  if (typeof value === "object" && value !== null) {
    // isEmpty 方法可以检查 value 是否为一个空对象、集合、映射或者 set。
    return _.isEmpty(value);
  } else {
    // isNil 方法可以检查一个值是否是 null 或者 undefined
    return _.isNil(value) || value === "";
  }
}

export function getQuery(fullPath) {
  const url = fullPath || window.location.href;
  const queryObj = {};
  if (!url.includes("?")) {
    return {};
  }
  url
    .split("?")[1]
    .split("&")
    .map((i) => (queryObj[i.split("=")[0]] = i.split("=")[1]));
  return queryObj;
}
export function isCapture() {
  return getQuery().type === "capture";
}

// 退出登录
export function logOutOfLogin() {
  // 清除cookies
  Cookies.remove("bidUserInfo");
  Cookies.remove("bidToken");
  // 刷新当前页
  window.location.href = "/login";
}
/** 深拷贝 **/
export function deepClone(source) {
  if (!source) return;
  const targetObj = source.constructor === Array ? [] : {}; // 判断复制的目标是数组还是对象
  for (let keys in source) {
    // 遍历目标
    if (Object.hasOwnProperty.call(source, keys)) {
      if (source[keys] && typeof source[keys] === "object") {
        // 如果值是对象，就递归一下
        targetObj[keys] = source[keys].constructor === Array ? [] : {};
        targetObj[keys] = deepClone(source[keys]);
      } else {
        // 如果不是，就直接赋值
        targetObj[keys] = source[keys];
      }
    }
  }
  return targetObj;
}

//时间戳转换年月日格式
export function getData(n) {
  let now = new Date(n),
    y = now.getFullYear(),
    m = now.getMonth() + 1,
    d = now.getDate();
  return y + "-" + (m < 10 ? "0" + m : m) + "-" + (d < 10 ? "0" + d : d);
}

/**
 * 时间显示效果显示
 * @param {String} time 需要转换的时间
 * @param {String} option 格式 {y}-{m}-{d} {h}:{i}:{s}
 * @return: {String} 转换完成的时间
 */
export function conversionTime(time) {
  time = +time * 1000;
  const d = new Date(time);
  const now = Date.now();
  if (d.toDateString() === new Date().toDateString()) {
    const diff = (now - d) / 1000;
    if (diff < 60) {
      return "1分钟前";
    } else if (diff < 3600) {
      // less 1 hour
      return Math.floor(diff / 60) + "分钟前";
    } else {
      return Math.floor(diff / 3600) + "小时前";
    }
  } else if (d < new Date()) {
    return getData(time);
  }
}

/**
 * 时间转换
 * @param {number} time 需要转换的时间
 * @param {String} cFormat 格式 {y}-{m}-{d} {h}:{i}:{s}
 * @return: {String} timeStr 转换完成的时间
 */
export function DateFormat(time, cFormat) {
  if (arguments.length === 0) {
    return "-";
  }
  if (time === null) {
    return "-";
  }
  const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";
  let date;
  if (time === "") {
    date = new Date();
  } else if (typeof time === "object") {
    date = time;
  } else {
    if (("" + time).length === 10) time = parseInt(time) * 1000;
    if (String(time).indexOf("T") > -1) {
      time = time.replace(/T/g, " ").replace(/\..*/g, "");
    }
    if (String(time).indexOf("-") > -1 && String(time).indexOf("T") === -1) {
      time = time.replace(/-/g, "/");
    }
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  };
  return format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key];
    if (key === "a") {
      return ["日", "一", "二", "三", "四", "五", "六"][value];
    }
    if (result.length > 0 && value < 10) {
      value = "0" + value;
    }
    return value || 0;
  });
}

//金额转换元或万元
export function formatNumberToMoney(num) {
  num = Number(num);
  if (num === 0) {
    return num + "元";
  } else if (num > 1 && num < 10000) {
    return num + "元";
  } else if (Object.is(num, NaN)) {
    return "-";
  } else {
    num = parseInt(num / 100);
    return num / 100 + "万元";
  }
}

// 获取登录状态
export const getLoginStatus = () => {
  return Cookies.get("bidUserInfo");
};
// 是否爬虫访问
export const isSpider = () => {
  const UA = navigator.userAgent;
  return UA.toLowerCase().indexOf("spider") !== -1;
};

// 是否是完整链接
export const getIsCompleteHref = (href) => {
  const hrefRegex = new RegExp("^(http:|https:|ftp:)?(//.+)", "i");
  return hrefRegex.test(href);
};

// 跳转方式 open是打开新页签，否则当前页打开
export const jumpPage = (url, type) => {
  if (type === "open") {
    window.open(url);
  } else {
    window.location.href = url;
  }
};
/** 生成唯一ID **/
export function getUUID() {
  return uuidv4();
}

/**
 * 高亮
 * @param {String} str
 * @param {Array} keywords
 * @returns
 */
export const highlight = (str, keywords) => {
  let formatStr = str;
  let keywordsJoin = keywords.join("|");

  // 格式化特殊字符
  const specialCharacter = ["\\", "$", "(", ")", "*", "+", ".", "[", "?", "^", "{"];
  specialCharacter.forEach((v) => {
    let reg = new RegExp("\\" + v, "gim");
    keywordsJoin = keywordsJoin.replace(reg, "\\" + v);
  });

  return formatStr.replace(new RegExp(`(${keywordsJoin})`, "g"), '<span style="color: #eb8100">$1</span>');
};
export function isRead(id) {
  let currentDate = DateFormat(new Date(), "{y}-{m}-{d}");
  let reaListData = JSON.parse(localStorage.getItem("readData")) || {};
  if (reaListData[currentDate] && reaListData[currentDate].includes(id)) {
    return true;
  } else {
    return false;
  }
}

export function addReadData(id) {
  let currentDate = DateFormat(new Date(), "{y}-{m}-{d}");
  let readData = JSON.parse(localStorage.getItem("readData")) || {};
  if (!readData[currentDate]) {
    readData = {};
    readData[currentDate] = [id];
  } else {
    readData[currentDate] = [...readData[currentDate], id];
    readData[currentDate] = Array.from(new Set(readData[currentDate]));
  }
  localStorage.setItem("readData", JSON.stringify(readData));
}

export function downFileBlob(res) {
  let blob = new Blob([res.data]); //res为从后台返回的数据
  let fileName = res.headers["content-disposition"].split("filename=").pop();
  fileName = decodeURIComponent(fileName);
  let downloadElement = document.createElement("a");
  // 创建下载的链接
  let href = window.URL.createObjectURL(blob);
  downloadElement.href = href;
  // 下载后文件名
  downloadElement.download = fileName.replace(new RegExp('"', "g"), "");
  document.body.appendChild(downloadElement);
  downloadElement.click();
  document.body.removeChild(downloadElement);
  // 释放掉blob对象
  window.URL.revokeObjectURL(href);
}
